@tailwind base;
@tailwind utilities;
@import url('https://api.fontshare.com/v2/css?f[]=satoshi@900,700,500,300,400&display=swap');

@layer base {
  html {
    font-family: 'Satoshi', 'sans-serif';
    box-sizing: border-box;
  }

  :root {
    --base-white: hsla(0, 0%, 100%, 1);
    --base-black: hsla(0, 0%, 0%, 1);
    --base-transparent: hsla(0, 0%, 100%, 0);

    --gray-50: hsla(0, 0%, 99.6%, 1);
    --gray-100: hsla(180, 20%, 99%, 1);
    --gray-200: hsla(240, 14.3%, 98.6%, 1);
    --gray-300: hsla(210, 20%, 98%, 1);
    --gray-400: hsla(210, 16.7%, 97.6%, 1);
    --gray-500: hsla(220, 20%, 97.1%, 1);
    --gray-600: hsla(220, 5.1%, 88.4%, 1);
    --gray-700: hsla(240, 1.3%, 69%, 1);
    --gray-800: hsla(210, 0.8%, 53.3%, 1);
    --gray-900: hsla(210, 1%, 40.8%, 1);

    --navy-50: hsla(240, 4.3%, 91%, 1);
    --navy-100: hsla(220, 4.3%, 72.9%, 1);
    --navy-200: hsla(218, 3.9%, 60%, 1);
    --navy-300: hsla(217, 6.2%, 41%, 1);
    --navy-400: hsla(220, 9.8%, 30%, 1);
    --navy-500: hsla(219, 30.3%, 12.9%, 1);
    --navy-600: hsla(220, 32.1%, 11%, 1);
    --navy-700: hsla(219, 30.4%, 9%, 1);
    --navy-800: hsla(218, 31.4%, 6.9%, 1);
    --navy-900: hsla(225, 30.8%, 5.1%, 1);

    --brand-50: hsla(231, 100%, 95.9%, 1);
    --brand-100: hsla(230, 100%, 87.1%, 1);
    --brand-200: hsla(230, 100%, 80.8%, 1);
    --brand-300: hsla(230, 100%, 72%, 1);
    --brand-400: hsla(230, 100%, 66.7%, 1);
    --brand-500: hsla(230, 100%, 58.2%, 1);
    --brand-600: hsla(230, 80.8%, 52.9%, 1);
    --brand-700: hsla(230, 71.6%, 41.4%, 1);
    --brand-800: hsla(230, 71.8%, 32%, 1);
    --brand-900: hsla(231, 71.2%, 24.5%, 1);

    --error-50: hsla(351, 61.3%, 93.9%, 1);
    --error-100: hsla(350, 63.8%, 81.6%, 1);
    --error-200: hsla(349, 63.1%, 72.4%, 1);
    --error-300: hsla(350, 62.9%, 59.8%, 1);
    --error-400: hsla(350, 63.1%, 52.2%, 1);
    --error-500: hsla(350, 63.1%, 52.2%, 1);
    --error-600: hsla(350, 94.1%, 40.2%, 1);
    --error-700: hsla(350, 94.6%, 36.5%, 1);
    --error-800: hsla(349, 94.5%, 28.4%, 1);
    --error-900: hsla(350, 93.1%, 17.1%, 1);

    --warning-50: hsla(43, 90%, 96.1%, 1);
    --warning-100: hsla(44, 90.2%, 88%, 1);
    --warning-200: hsla(44, 89%, 82.2%, 1);
    --warning-300: hsla(44, 89.5%, 73.9%, 1);
    --warning-400: hsla(44, 88.7%, 68.8%, 1);
    --warning-500: hsla(44, 88.9%, 61%, 1);
    --warning-600: hsla(44, 70.9%, 55.5%, 1);
    --warning-700: hsla(44, 56.6%, 43.3%, 1);
    --warning-800: hsla(44, 56.7%, 33.5%, 1);
    --warning-900: hsla(45, 56.9%, 25.5%, 1);

    --success-50: hsla(152, 55.6%, 94.7%, 1);
    --success-100: hsla(151, 53.5%, 83.1%, 1);
    --success-200: hsla(151, 54.3%, 75.1%, 1);
    --success-300: hsla(152, 53.8%, 63.5%, 1);
    --success-400: hsla(152, 54.1%, 56.5%, 1);
    --success-500: hsla(152, 64.7%, 45.5%, 1);
    --success-600: hsla(152, 64.9%, 41.4%, 1);
    --success-700: hsla(152, 64.8%, 32.4%, 1);
    --success-800: hsla(152, 64.1%, 25.1%, 1);
    --success-900: hsla(152, 64.9%, 19%, 1);

    /* Secondary Colors */
    --gray-blue-50: hsla(240, 20%, 99%, 1);
    --gray-blue-100: hsla(216, 33.3%, 97.1%, 1);
    --gray-blue-200: hsla(223, 28%, 95.1%, 1);
    --gray-blue-300: hsla(222, 27.8%, 92.9%, 1);
    --gray-blue-400: hsla(220, 26.1%, 91%, 1);
    --gray-blue-500: hsla(220, 27.3%, 89.2%, 1);
    --gray-blue-600: hsla(220, 15.5%, 81%, 1);
    --gray-blue-700: hsla(218, 5.8%, 62.9%, 1);
    --gray-blue-800: hsla(217, 3.2%, 49%, 1);
    --gray-blue-900: hsla(214, 3.7%, 37.1%, 1);
  }
}

[data-scrollbar] {
  height: 100vh;
}
